import React from 'react'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import Route from 'react-router-dom/Route'
import AppFrame from './AppFrame'
import PopupWrapper from 'shared-ui/components/popup/PopupWrapper'
import { ApiProvider } from 'shared-ui/api/ApiContext'
import ApiConnector from 'shared-ui/api/ApiConnector'
import AdminApi from './api/AdminApi'
import SnackMessages from './components/molecules/SnackMessages'
import ErrorHandler from 'shared-ui/components/pages/ErrorHandler'
import { ProvideLocalClipboard } from './hooks/useLocalClipboard'

import { SnackbarProvider } from 'notistack'
import ModelDetails from './components/page/ModelViewWrapper'
import ModelList from './components/page/ModelViewWrapper'
import AdminLoginPage from './components/page/AdminLoginPage'
import AdminLoginResetPage from 'shared-ui/components/pages/ResetPasswordPage'
import AdminLoginResetPasswordPage from './components/page/AdminLoginSetPasswordPage'
import AdminDashboard from './components/page/AdminDashboard'
import AdminVersionControlPage from './components/page/AdminVersionControlPage'
import GenericMessagePage from 'shared-ui/components/templates/GenericMessagePage'
import AdminVersionDiffPage from './components/page/AdminVersionDiffPage'
import AdminIssuesPage from './components/page/AdminIssuesPage'
import PortalThemeProvider from 'shared-ui/assets/styles/PortalThemeProvider'
import PageWrapper from 'shared-ui/components/pages/PageWrapper'

const apiConnector = new ApiConnector(process.env.REACT_APP_API_URL)
const adminApi = new AdminApi(apiConnector)

const componentByName = {
  ModelDetails,
  ModelList,
  AdminLoginPage,
  AdminLoginResetPage,
  AdminLoginResetPasswordPage,
  AdminDashboard,
  AdminVersionControlPage,
  AdminVersionDiffPage,
  GenericMessagePage,
  AdminIssuesPage,
}

const getComponentByName = name => {
  return componentByName[name]
}

// Yo dawg, I heard you liked wrappers, so I wrapped your wrapper in another wrapper
const PopupWrapperWrapper = props => (
  <PopupWrapper minWidth={'90vw'} maxWidth={960} maxHeight={'90vh'} {...props} />
)

function App() {
  return (
    <PortalThemeProvider lightSchemeOnly>
      <ProvideLocalClipboard>
        <BrowserRouter>
          <ErrorHandler>
            <ApiProvider api={adminApi}>
              <SnackbarProvider maxSnack={4} dense>
                <SnackMessages />
                <Route
                  render={({ location, history }) => (
                    <PageWrapper
                      getComponentByName={getComponentByName}
                      AppFrame={AppFrame}
                      PopupWrapper={PopupWrapperWrapper}
                      location={location}
                      history={history}
                    />
                  )}
                />
              </SnackbarProvider>
            </ApiProvider>
          </ErrorHandler>
        </BrowserRouter>
      </ProvideLocalClipboard>
    </PortalThemeProvider>
  )
}

export default App
