import React, { useCallback, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Icon, Menu, MenuItem } from '@material-ui/core'
import { arrayMove } from 'react-sortable-hoc'
import { withApi } from 'shared-ui/api/ApiContext'
import ElementsList from './ElementsList'
import useLocalClipboard from '~src/hooks/useLocalClipboard'
import { itemElementsByType } from './ElementsListItem'

const CLIPBOARD_PREFIX = 'CONTENT_ELEMENT_COPY:'

const styles = ({ palette, spacing: { unit } }) => ({
  list: {
    //
  },
  item: {
    listStyle: 'none',
  },
  noGutters: {
    margin: 0,
    padding: 0,
  },
  topBorder: {
    borderTop: `1px solid ${palette.grey300}`,
  },
  bottomButtonBar: {
    padding: unit,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>*': {
      marginLeft: unit,
    },
  },
  buttonIcon: {
    marginRight: unit * 0.5,
    marginLeft: -unit * 0.5,
  },
})

const idToValueAsObjectProperty = createEmptyObject => (targetObject, idValueObject) => ({
  ...targetObject,
  [idValueObject.id]: createEmptyObject
    ? {}
    : {
        ...idValueObject.value,
      },
})

const BottomButtonsBar = withStyles(styles)(({
  className,
  classes,
  disabled,
  onSelect,
  onPaste,
  elements = [],
}) => {
  const [anchorEl, setAnchorEl] = useState()

  const handleSelectButtonClick = useCallback(e => {
    setAnchorEl(e.currentTarget)
  }, [])
  const handleMenuClose = useCallback(() => {
    setAnchorEl(undefined)
  }, [])
  const handleMenuItemClick = useCallback(
    type => {
      onSelect && onSelect(type)
      setAnchorEl(undefined)
    },
    [onSelect],
  )

  return (
    <div className={classNames(className, classes.bottomButtonBar)}>
      <Button
        aria-owns={anchorEl ? 'new-conten-element-menu' : undefined}
        aria-haspopup="true"
        variant={'outlined'}
        disabled={disabled}
        onClick={handleSelectButtonClick}
      >
        <Icon className={classes.buttonIcon}>add</Icon> Add new section
      </Button>
      {!disabled && onPaste && (
        <Button variant={'outlined'} onClick={onPaste}>
          <Icon className={classes.buttonIcon}>paste</Icon> Paste Section
        </Button>
      )}
      <Menu
        id="new-conten-element-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        {elements.map(item => (
          <MenuItem onClick={() => handleMenuItemClick(item.key)}>{item.label}</MenuItem>
        ))}
      </Menu>
    </div>
  )
})

const ContentElements = ({
  classes,
  name,
  value,
  disabled,
  subScheme,
  onChange,
  _renderFields,
  _language,
  settings: { variant, elements: settingsElements, max } = {},
}) => {
  const desiredExpandId = useRef()

  value = value || []

  const clipBoard = useLocalClipboard()
  const pasteElement = useMemo(() => {
    try {
      const parsedJson = JSON.parse(clipBoard.text.split(CLIPBOARD_PREFIX)[1] || '""')
      if (!parsedJson) return null
      const { value: { elementType = '' } = {} } = parsedJson
      let allowed = settingsElements ? settingsElements.some(x => x === elementType) : true
      if (!allowed) return null
      return parsedJson
    } catch (e) {
      //
    }
  }, [clipBoard.text])

  const rawDatas = useMemo(() => value.reduce(idToValueAsObjectProperty(), {}), [value])
  const changed = useMemo(() => value.reduce(idToValueAsObjectProperty(true), {}), [value])
  const schemeData = useMemo(
    () =>
      Object.values(subScheme).map(s => ({
        ...s,
        disabled: s.disabled === undefined ? disabled : s.disabled,
      })),
    [subScheme, disabled],
  )

  const handleChange = useCallback(
    changedValue => {
      onChange &&
        onChange({
          target: {
            name,
            value: changedValue,
          },
        })
    },
    [onChange, name],
  )

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      handleChange(arrayMove(value, oldIndex, newIndex)) // TODO: think of ways to report reorder instructions instead of submitting all data
    },
    [name, value],
  )

  const fieldRenderProps = useCallback(
    elementId => {
      const handleFieldChange = ({ target }) => {
        handleChange(
          value.map(item => {
            // TODO: think of ways to report changes selectively instead of rewriting all data
            if (item.id === elementId) {
              const scheme = schemeData.find(s => s.name === target.name)
              const _languageSpecificProperties =
                scheme && scheme.settings && scheme.settings.multilang
                  ? {
                      [target.name + '__' + _language]:
                        target.value === '' ? undefined : target.value, // TODO: if selective data update implemented undefined will not work
                    }
                  : {
                      [target.name]: target.value === '' ? undefined : target.value, // TODO: if selective data update implemented undefined will not work
                    }

              return {
                ...item,
                value: {
                  ...item.value,
                  ..._languageSpecificProperties,
                },
              }
            }
            return item
          }),
        )
      }
      const handleFieldRequestSave = () => {
        //
      }
      const handleFieldTrigger = () => {
        //
      }
      return {
        state: {
          schemeData,
          rawData: rawDatas[elementId],
          changed: changed[elementId],
        },
        methods: {
          checkFieldVisibility: () => true,
          onChange: handleFieldChange,
          onRequestSave: handleFieldRequestSave,
          onTrigger: handleFieldTrigger,
        },
      }
    },
    [name, value, _language, rawDatas, schemeData],
  )

  const addNewElement = useCallback(
    props => {
      const { value: { elementType = '' } = {} } = props

      const fields = itemElementsByType.find(x => x.key === elementType)?.value || []
      const defaultValues = fields.reduce((acc, field) => {
        acc[field] = schemeData.find(i => i.name === field)?.defaultValue
        return acc
      }, {})

      const allowed = settingsElements ? settingsElements.some(x => x === elementType) : true
      if (!allowed) return null
      const newId = Date.now().toString() // TODO: think of other ways to assign id
      desiredExpandId.current = newId
      handleChange([
        ...value,
        {
          ...props,
          value: {
            ...defaultValues,
            ...props.value,
          },
          id: newId,
        },
      ])
    },
    [value, handleChange],
  )

  const handleNewElementSelect = useCallback(
    type => addNewElement({ value: { elementType: type } }),
    [value, addNewElement],
  )
  const handlePaste = useCallback(() => {
    addNewElement(pasteElement)
    clipBoard.write('""')
  }, [clipBoard, addNewElement, pasteElement])

  const handleItemDelete = useCallback(
    id => {
      handleChange(value.filter(item => item.id !== id))
    },
    [value],
  )
  const handleItemCopy = useCallback(
    id => {
      const item = value.find(valueItem => valueItem.id === id)
      clipBoard.write(item ? CLIPBOARD_PREFIX + JSON.stringify(item) : '')
    },
    [value, handleChange],
  )
  const handleItemCut = useCallback(
    id => {
      handleItemCopy(id)
      handleItemDelete(id)
    },
    [value, handleItemCopy, handleItemDelete],
  )

  const renderFields = useMemo(() => {
    return (fields, elementId) => _renderFields(fields, fieldRenderProps(elementId))
  }, [fieldRenderProps])

  const itemHandlers = useMemo(
    () =>
      disabled
        ? {}
        : {
            onItemDelete: handleItemDelete,
            onItemCopy: handleItemCopy,
            onItemCut: handleItemCut,
          },
    [disabled, handleItemDelete, handleItemCopy, handleItemCut],
  )

  let disableAdd = false
  if (variant === 'contentSubElements') {
    disableAdd = value.length >= (max || 4)
  }

  const elements = settingsElements
    ? itemElementsByType.filter(x => settingsElements.includes(x.key))
    : itemElementsByType

  return (
    <React.Fragment>
      <ElementsList
        disabled={disabled}
        className={classNames(classes.list, classes.noGutters)}
        classes={{ item: classNames(classes.noGutters, classes.topBorder, classes.item) }}
        elements={value}
        onSortEnd={handleSortEnd}
        {...itemHandlers}
        useDragHandle
        lockAxis={'y'}
        distance={10}
        renderFields={renderFields}
        language={_language}
        desiredExpandId={desiredExpandId.current}
      />
      {!disableAdd && (
        <BottomButtonsBar
          className={classes.topBorder}
          onSelect={!disabled ? handleNewElementSelect : undefined}
          onPaste={pasteElement ? handlePaste : undefined}
          disabled={disabled}
          variant={variant}
          elements={elements}
        />
      )}
    </React.Fragment>
  )
}

export default withApi(withStyles(styles)(ContentElements))
