import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/neat.css'
import 'codemirror/theme/neo.css'
import 'codemirror/mode/javascript/javascript.js'

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Controlled as CodeMirror } from 'react-codemirror2'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = () => ({
  codeContainer: {
    maxHeight: 1000,
    overflow: 'auto',
  },
})

class CustomJSON extends Component {
  handleChange = (editor, data, value) => {
    const { onUpdate } = this.props

    onUpdate && onUpdate(value)
  }

  handleBlur = () => {
    const { onChange, name, value } = this.props

    onChange &&
      onChange({
        target: {
          name,
          value,
        },
      })
  }

  render() {
    const { label, disabled, value, helperText, classes } = this.props

    return (
      <React.Fragment>
        <Typography variant="subtitle1">{label}</Typography>
        <CodeMirror
          ref={ref => ref && this._trySettingAutoHeight(ref)}
          value={JSON.stringify(value, null, 4)}
          className={classes.codeContainer}
          options={{
            theme: 'neo',
            mode: 'javascript',
            lineNumbers: true,
            readOnly: disabled,
            autoScroll: true,
          }}
          onBeforeChange={(...props) => {
            this.handleChange(...props)
          }}
          onBlur={this.handleBlur}
          name={this.props.name}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </React.Fragment>
    )
  }

  _trySettingAutoHeight(ref) {
    try {
      ref.editor.display.wrapper.style.height = 'auto'
    } catch (err) {
      console.error('CustomJSON._trySettingAutoHeight:', err)
    }
  }
}

export default withStyles(styles)(CustomJSON)
