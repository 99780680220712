import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Button from '@material-ui/core/Button'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import Link from 'shared-ui/components/Link'

class AdminVersionControlPage extends React.Component {
  state = {
    expanded: '@next',
    updating: false,
    updateInfo: 'No Info',
    timer: null,
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  handleUpdateClick = async event => {
    if (
      !window.confirm('🚨 Do you really know what you are doing? 🚨') ||
      !window.confirm('The system will be updated – this could take up to 5 minutes')
    )
      return

    const { api } = this.props
    const { request } = api.versionUpdate()
    const response = await request

    this.setState(response)
  }

  async tick() {
    const { api } = this.props
    const { request } = api.versionInfo()
    const response = await request

    this.setState(response)
  }

  componentDidMount() {
    const {
      version: { state },
    } = this.props

    this.interval = setInterval(() => this.tick(), 5000)
    this.setState(state)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {
      classes,
      version: { history, stats },
      modelHistory,
      canUpdateVersion,
    } = this.props
    const { expanded } = this.state

    return (
      <React.Fragment>
        {this.state.updating && (
          <Paper className={classes.dangerZone}>
            <Grid container spacing={24}>
              <Grid item xs container direction="column" className={classes.logWindow}>
                {this.state.updateInfo.split('\n').map(t => {
                  return <Typography className={classes.log}>{t}</Typography>
                })}
              </Grid>
              <Grid item justify="center">
                <CircularProgress color="secondary" className={classes.progress} />
              </Grid>
            </Grid>
          </Paper>
        )}
        {!this.state.updating && canUpdateVersion && (
          <Paper className={classes.dangerZone}>
            <Grid container spacing={24}>
              <Grid item xs container direction="column" justify="center">
                <Chip label={stats.m} className={classes.chip} />
              </Grid>
              <Grid item>
                {stats && stats.commits ? (
                  <Badge color="error" badgeContent={stats.commits} className={classes.margin}>
                    <Button variant="contained" onClick={this.handleUpdateClick}>
                      Update System
                    </Button>
                  </Badge>
                ) : (
                  <Button variant="contained" disabled>
                    Update System
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        )}

        <div className={classes.versionList}>
          {history.map(version => {
            const { title, features } = version
            const bareVersion = title.startsWith('@') ? title : title.slice(1)
            return (
              <ExpansionPanel expanded={expanded === title} onChange={this.handleChange(title)}>
                <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                  <span>
                    <Chip label={title} color="secondary" />
                    {modelHistory.includes(bareVersion) && (
                      <Button
                        classes={{ root: classes.modelDiffButton }}
                        component={Link}
                        to={'/version/diff/' + bareVersion}
                      >
                        model changes
                      </Button>
                    )}
                  </span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.versionTable}>
                  <Table className={classes.versionTable}>
                    <TableBody>
                      {features.map(f => {
                        const { hash, title, link } = f
                        return (
                          <TableRow>
                            <TableCell>
                              <a href={link} target="_blank" rel="noopener noreferrer">
                                <Button size="small" className={classes.button}>
                                  TICKET
                                </Button>
                              </a>
                            </TableCell>
                            <TableCell>{hash}</TableCell>
                            <TableCell>{title}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

const styles = ({ spacing: { unit } }) => ({
  heading: {
    fontSize: '1.5rem',
  },
  versionList: {
    // margin: '3rem'
  },
  versionTable: {
    padding: 0,
  },
  dangerZone: {
    marginBottom: unit * 2,
    padding: unit * 3,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  logWindow: {
    backgroundColor: '#efefef',
    borderRadius: unit,
  },
  log: {
    fontFamily: 'monospace',
    fontSize: '12px',
  },
  modelDiffButton: {
    marginLeft: unit,
    paddingRight: `12px !important`,
    border: '1px solid #efefef',
  },
})

export default withStyles(styles)(AdminVersionControlPage)
