import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Chip from '@material-ui/core/Chip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ColorPicker from './ColorPicker'
import ColorDrop from './ColorDrop'
import Fade from '@material-ui/core/Fade'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = ({ spacing: { unit } }) => ({
  root: {
    width: '100%',
    '& label + $chip': {
      marginTop: unit * 1.5,
    },
  },
  selectMenu: {
    width: 'auto',
  },
  chip: {},
  avatar: {
    boxShadow: `inset 0 0 0 1px rgba(0,0,0,0.25)`,
  },
})

class CustomColor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pickedColor: props.value,
      popperOpen: false,
    }
  }

  _closePopper = () => {
    this.setState({ popperOpen: false })
    const { pickedColor } = this.state
    this._propageteChange(pickedColor)
  }

  _propageteChange = pickedColor => {
    let { name, onChange } = this.props
    onChange &&
      onChange({
        target: {
          type: 'color',
          name,
          value: pickedColor,
        },
      })
  }

  _openPopper = e => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    this.setState({ popperOpen: true, anchorEl: e.currentTarget })
  }

  _onChange = ({ hex: pickedColor }) => {
    if (this.state.pickedColor === pickedColor) {
      return
    }

    this.setState({ pickedColor })
  }

  _onClear = e => {
    this.setState({ pickedColor: undefined, popperOpen: false })
    this._propageteChange(null)
  }

  render() {
    const { classes, label, required, value: color, helperText, disabled } = this.props
    const { popperOpen, anchorEl, pickedColor } = this.state

    return (
      <FormControl required={required} className={classes.root}>
        {label && (
          <InputLabel shrink disabled={disabled}>
            {label}
          </InputLabel>
        )}

        <Chip
          avatar={<ColorDrop color={color} />}
          clickable={!disabled}
          onClick={!disabled && this._openPopper}
          label={<pre>{color && color.toUpperCase()}</pre>}
          className={classes.chip}
        />

        <Popper
          style={{ zIndex: 9999 }}
          placement="bottom-start"
          transition
          open={popperOpen}
          anchorEl={anchorEl}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this._closePopper}>
              <Fade {...TransitionProps} timeout={150}>
                <Paper>
                  <ColorPicker
                    disableAlpha
                    color={pickedColor ?? undefined}
                    onChange={this._onChange}
                    onClear={this._onClear}
                  />
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withStyles(styles)(CustomColor)
