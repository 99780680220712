import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import LoginForm from 'shared-ui/components/organisms/Form'
import SystemPageTemplate from 'shared-ui/components/templates/SystemPageTemplate'
import { Button } from '@material-ui/core'
import logo from 'shared-ui/assets/img/msal.svg'

const fields = [
  {
    value: '',
    name: 'emailAddress',
    type: 'email',
    defaultColumn: true,
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    value: '',
  },
]

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  container: {
    height: 'inherit',
    minHeight: 'calc(100vh - 64px)', // 64px refers to Header height
    '& > *': {
      position: 'relative',
    },
  },
  extra: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
  },
  msSignin: {
    backgroundColor: palette.grey500,
    color: palette.paper,
    '&:hover': {
      backgroundColor: palette.grey400,
    },
  },
  logo: {
    width: '1.5rem',
    paddingRight: '0.5rem',
  },
})

const LoginWithMicrosoft = props => {
  const { classes, api } = props
  return (
    <div className={classes.extra} onClick={() => api.msalLogin()}>
      <Button className={classes.msSignin}>
        <img className={classes.logo} src={logo} alt="msal.svg" />
        Sign in with Microsoft
      </Button>
    </div>
  )
}

class AdminLoginPage extends React.Component {
  state = { schemeData: fields }

  render() {
    const {
      api,
      pageTitle,
      loginForm,
      classes,
      settings: { technical },
    } = this.props

    let props
    technical.connexusLoginEnabled &&
      (props = { ...props, ...loginForm, connexusLoginEnabled: true })
    technical.msLoginEnabled &&
      (props = { ...props, extra: <LoginWithMicrosoft {...this.props} /> })

    return (
      <SystemPageTemplate title={pageTitle} className={classes.container}>
        <LoginForm
          {...props}
          onSubmitClick={data => {
            api.login({ form: data })
          }}
        />
      </SystemPageTemplate>
    )
  }
}

export default withStyles(styles)(AdminLoginPage)
