import React from 'react'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import withRouter from 'react-router-dom/withRouter'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'

const styles = ({ palette, spacing: { unit } }) => ({
  button: {
    width: '100%',
    padding: 0,
    marginLeft: -3,
    marginBottom: unit * 2,
    color: palette.primary.main,
    justifyContent: 'inherit',
  },
  leftIcon: {
    color: palette.primary.main,
    marginRight: unit * 3,
  },
  text: {
    color: palette.primary.main,
    textTransform: 'initial',
  },
})

const SmallButton = withStyles(styles)(props => {
  const { text, icon, classes, href, onClick, disabled, history, className } = props

  const handleClick = e => {
    if (onClick) onClick(e)

    if (href) history.push(href)
  }

  return (
    <Button
      size="small"
      className={classNames(classes.button, className)}
      onClick={handleClick}
      disabled={disabled}
    >
      <Icon className={classes.leftIcon}>{icon}</Icon>
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    </Button>
  )
})

export default withRouter(SmallButton)
